import React from 'react';
import { Form } from 'react-bootstrap';

const SearchBar = ({ enableSearch, searchTerm, setSearchTerm, language, localization, items, itemNames }) => {

    const filteredItems = items
    .flatMap(category => category.items.map(item => ({
        category: category.category,
        ...item
    })))
    .filter(item => {
        const itemName = itemNames[item.name]?.[language] || itemNames[item.name]?.en || item.name;
        return String(itemName).toLowerCase().includes(searchTerm.toLowerCase());
    });

    return (
        enableSearch && (
            <Form.Control type="text" placeholder={localization.searchPlaceholder} onChange={e => setSearchTerm(e.target.value)} />
        )
    );
}

export default SearchBar;
