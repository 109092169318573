import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Overlay, Popover } from 'react-bootstrap';
import { FaWhatsapp, FaRegCopy, FaTrash } from 'react-icons/fa';
import ReactGA from 'react-ga4';

const ShowUserList = ({ showOverlay, setShowOverlay, setShowListCopiedAlert, setShowListClearedAlert, target, currentRef, language, localization, selectedItems, setSelectedItems, itemNames, brandNames, measureUnits, enableWhatsAppFooter }) => {
  const handleShare = () => {
    // Track button click event
    ReactGA.event({
      category: 'User Interaction',
      action: 'WhatsApp Share Button Clicked',
      label: 'WhatsApp Share Button'
    });

    ReactGA.event({
      category: 'Shopping List',
      action: 'List Finalized',
      label: `Finalized List With Item Count: ${selectedItems.length}`,
      item_count: `${selectedItems.length}`,
      nonInteraction: true
    });

    const list = Object.entries(selectedItems).map(([baseName, { quantity, unit, brand }]) => {
      const displayName = itemNames[baseName]?.[language] || itemNames[baseName]?.en || baseName;
      const brandName = brandNames[brand]?.[language] || brandNames[brand]?.en || brand;
      return `${displayName} ${brandName != null ? brandName : ''}: ${quantity} ${measureUnits[unit]?.[language] || measureUnits[unit]?.en || unit}`;
    }).join('\n');
    const message = list + (enableWhatsAppFooter ? `\n${localization.whatsappFooter}` : '');
    const url = `https://wa.me/?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };

  const handleCopy = () => {
    // Track button click event
    ReactGA.event({
      category: 'User Interaction',
      action: 'Clicked on Copy Button',
      label: 'Copy Button'
    });
    const list = Object.entries(selectedItems).map(([baseName, { quantity, unit, brand }]) => {
      const displayName = itemNames[baseName]?.[language] || itemNames[baseName]?.en || baseName;
      const brandName = brandNames[brand]?.[language] || brandNames[brand]?.en || brand;
      return `${displayName} ${brandName != null ? brandName : ''}: ${quantity} ${measureUnits[unit]?.[language] || measureUnits[unit]?.en || unit}`;
    }).join('\n');
    navigator.clipboard.writeText(list);
    setShowListCopiedAlert(true);
    setTimeout(() => setShowListCopiedAlert(false), 3000);
  };

  const handleClearList = () => {
    // Track button click event
    ReactGA.event({
      category: 'User Interaction',
      action: 'Clear List Button Clicked',
      label: `Clear List Button With Item Count: ${selectedItems.length}`
    });
    setSelectedItems({});
    setShowListClearedAlert(true);
    setTimeout(() => setShowListClearedAlert(false), 3000);
  };

  const getItemsByCategory = (selectedItems) => {
    const categoryItems = {};

    Object.entries(selectedItems).forEach(([itemName, itemData]) => {
      const category = itemData.category;
      if (!categoryItems[category]) {
        categoryItems[category] = [];
      }
      categoryItems[category].push({ name: itemName, ...itemData });
    });

    return categoryItems;
  };

  const handleDeleteItem = (baseName) => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Item Delete Button Clicked',
      label: `Item deleted ${baseName}`
    });
    const newSelectedItems = { ...selectedItems };
    delete newSelectedItems[baseName];
    setSelectedItems(newSelectedItems);
  };

  return (
    <Overlay style={{ position: 'fixed', top: '10%', right: '10%', zIndex: 1040 }} show={showOverlay} target={target} placement="bottom" container={currentRef} containerPadding={20} onHide={() => setShowOverlay(false)} className="sticky-overlay">
      <Popover id="popover-contained">
        <Popover.Header as="h3"><strong>{localization.yourList}</strong></Popover.Header>
        <Popover.Body>
          {Object.keys(selectedItems).length > 0 ? (

            <ul className="list-group">
              {Object.entries(getItemsByCategory(selectedItems)).flatMap(([category, itemsInCategory]) =>
                itemsInCategory.map((item) => {
                  const baseName = item.name; // Correctly access the item's name
                  const { quantity, unit, brand } = item; // Destructure quantity, unit, and brand from the item
                  const itemName = itemNames[baseName]?.[language] || itemNames[baseName]?.en || baseName;
                  const brandName = brandNames[brand]?.[language] || brandNames[brand]?.en || brand;
                  return (
                    <li key={baseName} className="list-group-item d-flex justify-content-between align-items-center">
                      <span>{itemName} {brandName}: {quantity} {measureUnits[unit]?.[language] || measureUnits[unit]?.en || unit}</span>
                      <Button variant="danger" size="sm" className="ml-auto" onClick={() => handleDeleteItem(baseName)}>{localization.delete}</Button>
                    </li>
                  );
                }))}
            </ul>
          ) : (
            <p>{localization.noItems}</p>
          )}
          <Button className="mt-3 btn-success" disabled={Object.keys(selectedItems).length === 0} onClick={handleShare}><FaWhatsapp />{localization.share}</Button>
          <Button className="mt-3" disabled={Object.keys(selectedItems).length === 0} onClick={handleCopy}><FaRegCopy />{localization.copy}</Button>
          <Button className="mt-3 btn-danger" variant="secondary" disabled={Object.keys(selectedItems).length === 0} onClick={handleClearList}><FaTrash />{localization.clearList}</Button>
        </Popover.Body>
      </Popover>
    </Overlay>
  );
};

export default ShowUserList;
