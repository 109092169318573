import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Alert, Overlay, Popover } from 'react-bootstrap';
import { FaList } from 'react-icons/fa';
import ReactGA from 'react-ga4';
import LanguageSelector from './LanguageSelector';
import ShowUserListOverlay from './ShowUserListOverlay';
import GoToList from './GoToList';
import StickyCategoryDropdown from './StickyCategoryDropdown';
import CategoryItemsList from './ItemList';
import SearchBar from './SearchBar';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const HomeSanitywareList = () => {
    const [items, setItems] = useState([]);
    const [selectedItems, setSelectedItems] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const [language, setLanguage] = useState(localStorage.getItem('language') || 'hi'); // Get language from localStorage or default to 'hi'
    const [localization, setLocalization] = useState({});
    const [measureUnits, setMeasureUnits] = useState({});
    const [itemNames, setItemNames] = useState({});
    const [categories, setCategories] = useState({});
    const [collapsedCategories, setCollapsedCategories] = useState({});
    const [showListClearedAlert, setShowListClearedAlert] = useState(false);
    const [showListCopiedAlert, setShowListCopiedAlert] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false);
    const [target, setTarget] = useState(null);
    const [brandNames, setBrandNames] = useState({});
    const [brandGroups, setBrandGroups] = useState({});
    //flags for features to enable/disable
    const [showBrandDropdown, setShowBrandDropdown] = useState(true); // Flag to control brand dropdown
    const [enableSearch, setEnableSearch] = useState(false); // Flag to enable/disable search feature
    const [enableWhatsAppFooter, setEnableWhatsAppFooter] = useState(true); // Flag to enable/disable whatsapp footer
    const [showCategoryDropdown, setShowCategoryDropdown] = useState(false); //for category dropdown

    const ref = useRef(null);
    const [showGuide, setShowGuide] = useState(false); // Control the visibility of the guide
    const [stepIndex, setStepIndex] = useState(0); // Track the current step
    const steps = [
        { target: "#language-dropdown", content: "Select your preferred language here" },
        { target: "#input-group-dropdown-1", content: "Choose the measure unit here" },
        { target: ".fa-list", content: "View your list of selected items here" }
    ];

    useEffect(() => {
        // Initialize Google Analytics with your tracking ID
        ReactGA.initialize('G-PF61178R7Y');

        // Track the initial page view
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname });

        fetch('/sanityware/data.json')
            .then(response => response.json())
            .then(data => {
                setItems(data.groceryItems);
            });

        fetch('/measureUnits.json')
            .then(response => response.json())
            .then(data => {
                setMeasureUnits(data);
            });

        fetch('/sanityware/itemNames.json')
            .then(response => response.json())
            .then(data => {
                setItemNames(data);
            });

        fetch('/sanityware/categories.json')
            .then(response => response.json())
            .then(data => {
                setCategories(data);
            });

        fetch(`/locales/${language}.json`)
            .then(response => response.json())
            .then(data => setLocalization(data));

        fetch('/sanityware/brandNames.json')
            .then(response => response.json())
            .then(data => setBrandNames(data));

        fetch('/sanityware/brandGroups.json')
            .then(response => response.json())
            .then(data => setBrandGroups(data));

        const firstTime = localStorage.getItem('firstTimeUser');
        /*if (!firstTime) {
            setShowGuide(true);
            localStorage.setItem('firstTimeUser', 'false'); // Mark the guide as shown
        }*/
    }, [language]);

    const handleDeleteItem = (baseName) => {
        ReactGA.event({
            category: 'User Interaction',
            action: 'Item Delete Button Clicked',
            label: `Item deleted ${baseName}`
        });
        const newSelectedItems = { ...selectedItems };
        delete newSelectedItems[baseName];
        setSelectedItems(newSelectedItems);
    };

    const handleOverlayToggle = (event) => {
        setTarget(event.target);
        setShowOverlay(!showOverlay);
    };

    const handleNextStep = () => {
        if (stepIndex < steps.length - 1) {
            setStepIndex(stepIndex + 1);
        } else {
            //setShowGuide(false);
        }
    };

    return (
        <Container ref={ref}>
            <Helmet htmlAttributes={{
                lang: language,
            }} >
                <title>{localization.homesanitywarelistSEOTitle}</title>
                <meta name="description" content={localization.homesanitywarelistSEODescription} />
                <meta name="keywords" content={localization.homesanitywarelistSEOKeywords} />
                {/* Open Graph Tags for Social Sharing */}
                <meta property="og:title" content={localization.homesanitywarelistSEOTitle} />
                <meta property="og:description" content={localization.homesanitywarelistSEODescription} />
                <meta property="og:image" content="https://www.samanlist.com/samanlist_logo.jpg" />
                <meta property="og:url" content="https://www.samanlist.com" />
                {/* Twitter Cards */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={localization.homesanitywarelistSEOTitle} />
                <meta name="twitter:description" content={localization.homesanitywarelistSEODescription} />
                <meta name="twitter:image" content="https://www.samanlist.com/samanlist_logo.jpg" />
            </Helmet>
            {showListClearedAlert && <Alert variant="success">{localization.listCleared}</Alert>}
            {showListCopiedAlert && <Alert variant="success">{localization.copySuccess}</Alert>}
            <Row className="my-4">
                <Col>
                </Col>
                <Col xs="auto">
                    <LanguageSelector language={language} setLanguage={setLanguage} localization={localization} />
                </Col>
                <Col xs="auto">
                    <Button id="selectedItemsList" variant="light" onClick={handleOverlayToggle}>
                        <FaList style={{ fontSize: '24px' }} />
                        <span className="circle-badge">
                            {Object.keys(selectedItems).length}
                        </span>
                    </Button>
                </Col>
            </Row>
            <Row className="my-4">
                <Col>
                    <SearchBar
                        enableSearch={enableSearch}
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        language={language}
                        localization={localization}
                        items={items}
                        itemNames={itemNames}
                    />
                    <h1><strong>{localization.homesanitywarepageheading}</strong></h1>
                    <p>{localization.homesanitywarelistinfo}</p>
                    <p>{localization.checkOtherLists} : <Link to="/kirana-saman-list">{localization.pageheading}</Link> <Link to="/modular-kitchen-furniture-saman-list">{localization.modularkitchenpageheading}</Link></p>
                </Col>
            </Row>
            <CategoryItemsList
                items={items}
                categories={categories}
                language={language}
                itemNames={itemNames}
                selectedItems={selectedItems}
                measureUnits={measureUnits}
                brandGroups={brandGroups}  // Pass your brandGroups here
                showBrandDropdown={showBrandDropdown}  // Set to true or false based on condition
                brandNames={brandNames}  // Pass your brand names here
                localization={localization}
                setSelectedItems={setSelectedItems}
                collapsedCategories={collapsedCategories}
                setCollapsedCategories={setCollapsedCategories}
            />
            {/* Sticky Category Dropdown Button */}
            <Row className="my-4">
                <Col xs="auto">
                    {/* Dropdown for categories */}
                    <StickyCategoryDropdown
                        setShowOverlay={setShowOverlay}
                        localization={localization}
                        language={language}
                        categories={categories}
                        items={items}
                    />
                </Col>
            </Row>
            <Row className="my-4">
                <Col xs="auto">
                    <GoToList
                        setTarget={setTarget}
                        setShowOverlay={setShowOverlay}
                        localization={localization}
                        selectedItems={selectedItems}
                    />
                </Col>
            </Row>

            {showGuide && steps[stepIndex] && (
                <Overlay target={document.querySelector(steps[stepIndex].target)} show={true} placement="bottom">
                    <Popover id="guide-popover">
                        <Popover.Body>
                            {steps[stepIndex].content}
                            <Button className="mt-2" onClick={handleNextStep}>
                                {stepIndex < steps.length - 1 ? 'Next' : 'Finish'}
                            </Button>
                        </Popover.Body>
                    </Popover>
                </Overlay>
            )}
            <ShowUserListOverlay
                showOverlay={showOverlay}
                setShowOverlay={setShowOverlay}
                setShowListCopiedAlert={setShowListCopiedAlert}
                setShowListClearedAlert={setShowListClearedAlert}
                target={target}
                currentRef={ref.current}
                language={language}
                localization={localization}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                itemNames={itemNames}
                brandNames={brandNames}
                measureUnits={measureUnits}
                enableWhatsAppFooter={enableWhatsAppFooter}
            />
        </Container>
    );
}

export default HomeSanitywareList;
