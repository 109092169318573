import React from 'react';
import { Button } from 'react-bootstrap';
import ReactGA from 'react-ga4';
import '../App.css';

const GoToList = ({ setTarget, setShowOverlay, localization, selectedItems }) => {

    const scrollToList = (event) => {
        ReactGA.event({
            category: 'User Interaction',
            action: 'Go to list button clicked',
            label: `Go to list button clicked With Item Count: ${selectedItems.length}`
        });
        const listIcon = document.getElementById('selectedItemsList'); // Replace with your actual list icon ID
        if (listIcon) {
            listIcon.scrollIntoView({ behavior: 'smooth' });
            setTarget(listIcon);
            setShowOverlay(true);
        }
    }

    return (
        <Button className="sticky-btn" onClick={scrollToList}><strong>{localization.gotolist}</strong></Button>
    );
};

export default GoToList;
