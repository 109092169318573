import React from 'react';
import { Collapse, Form, InputGroup, DropdownButton, Dropdown, Button } from 'react-bootstrap';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';
import ReactGA from 'react-ga4';

const CategoryItemsList = ({
  items,
  categories,
  language,
  itemNames,
  selectedItems,
  measureUnits,
  brandGroups,
  showBrandDropdown,
  brandNames,
  localization,
  setSelectedItems,
  collapsedCategories,
  setCollapsedCategories
}) => {

  const handleCheckboxChange = (item, selectedUnit, categoryName) => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Item Checkbox Checked',
      label: `Item Checked/unchecked ${item.name}`,
      item_name: `${item.name}`
    });
    const baseName = item.name; // Use the English name or any unique identifier as the base name
    if (selectedItems[baseName]) {
      handleDeleteItem(baseName);
    } else {
      setSelectedItems(prev => ({
        ...prev,
        [baseName]: {
          quantity: item.measureUnits[selectedUnit],
          unit: selectedUnit,
          stepQuantity: item.measureUnits[selectedUnit],
          brand: brandGroups[item.brandGroup]?.sponsored || null,
          category: categoryName
        }
      }));
    }
  };

  const handleQuantityChange = (baseName, delta) => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Quantity Selector Button Clicked',
      label: `Quantity Changed for ${baseName}`,
      item_name: `${baseName}`
    });
    setSelectedItems(prev => {
      const updatedQuantity = Math.max(prev[baseName].stepQuantity, prev[baseName].quantity + delta);
      return {
        ...prev,
        [baseName]: { ...prev[baseName], quantity: updatedQuantity }
      };
    });
  };

  const handleUnitChange = (item, newUnit) => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Unit Dropdown Clicked',
      label: `Unit Changed for ${item.name} with ${newUnit}`,
      item: `${item.name}`,
      unit: `${newUnit}`
    });
    const baseName = item.name; // Use the English name or any unique identifier as the base name
    handleCheckboxChange(item, newUnit);
    setSelectedItems(prev => ({
      ...prev,
      [baseName]: {
        quantity: item.measureUnits[newUnit],
        unit: newUnit,
        stepQuantity: item.measureUnits[newUnit]
      }
    }));
  };

  const handleBrandChange = (baseName, newBrand) => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Brand Dropdown Clicked',
      label: `Brand Changed for ${baseName} ${newBrand}`
    });
    setSelectedItems(prev => ({
      ...prev,
      [baseName]: { ...prev[baseName], brand: newBrand }
    }));
  };

  const handleDeleteItem = (baseName) => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Item Delete Button Clicked',
      label: `Item deleted ${baseName}`
    });
    const newSelectedItems = { ...selectedItems };
    delete newSelectedItems[baseName];
    setSelectedItems(newSelectedItems);
  };

  const toggleCategoryCollapse = (category) => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Category Collpased',
      label: `Category collapsed ${category}`
    });
    setCollapsedCategories(prev => ({
      ...prev,
      [category]: !prev[category]
    }));
  };


  return (
    <>
      {items.map(category => {
        const categoryName = categories[category.category]?.[language] || categories[category.category]?.en || category.category;
        const isCollapsed = collapsedCategories[category.category];

        return (
          <div key={category.category} id={category.category} className="mb-3">
            <h5 onClick={() => toggleCategoryCollapse(category.category)} style={{ cursor: 'pointer' }}>
              <strong>{isCollapsed ? <FaChevronRight /> : <FaChevronDown />} {categoryName}</strong>
            </h5>
            <Collapse in={!isCollapsed}>
              <div>
                {category.items.map(item => {
                  const itemName = itemNames[item.name]?.[language] || itemNames[item.name]?.en || item.name;
                  const baseName = item.name;
                  const selectedItem = selectedItems[baseName];
                  const selectedUnit = selectedItem ? selectedItem.unit : item.defaultMeasureUnit;
                  const measureUnit = measureUnits[selectedUnit]?.[language] || measureUnits[selectedUnit]?.en || selectedUnit;
                  const brandGroup = brandGroups[item.brandGroup];
                  const selectedBrand = selectedItem?.brand || brandGroup?.sponsored;

                  return (
                    <div key={item.name} className="mb-3">
                      <Form.Check
                        type="checkbox"
                        id={itemName}
                        label={itemName}
                        checked={!!selectedItems[baseName]}
                        onChange={() => handleCheckboxChange(item, selectedUnit, category.category)}
                      />
                      {selectedItems[baseName] && (
                        <InputGroup className="mt-2">
                          <DropdownButton
                            as={InputGroup.Prepend}
                            variant="outline-secondary"
                            title={measureUnit}
                            id="input-group-dropdown-1"
                            onSelect={(unit) => handleUnitChange(item, unit)}
                          >
                            {Object.keys(item.measureUnits).map(unit => (
                              <Dropdown.Item key={unit} eventKey={unit}>
                                {measureUnits[unit]?.[language] || measureUnits[unit]?.en || unit}
                              </Dropdown.Item>
                            ))}
                          </DropdownButton>
                          <Button
                            variant="outline-secondary"
                            onClick={() => handleQuantityChange(baseName, -selectedItems[baseName].stepQuantity)}
                          >
                            -
                          </Button>
                          <Form.Control
                            type="text"
                            value={selectedItems[baseName].quantity}
                            readOnly
                            style={{ maxWidth: '60px', textAlign: 'center' }}
                          />
                          <Button
                            variant="outline-secondary"
                            onClick={() => handleQuantityChange(baseName, selectedItems[baseName].stepQuantity)}
                          >
                            +
                          </Button>
                          {showBrandDropdown && brandGroup && (
                            <DropdownButton
                              as={InputGroup.Prepend}
                              variant="outline-secondary"
                              title={brandNames[selectedBrand]?.[language] || brandNames[selectedBrand]?.en || selectedBrand}
                              id="input-group-dropdown-2"
                              onSelect={(brand) => handleBrandChange(baseName, brand)}
                            >
                              {brandGroup.brands.map((brandObj, index) => (
                                <Dropdown.Item key={index} eventKey={brandObj}>
                                  {brandNames[brandObj]?.[language] || brandNames[brandObj]?.en || brandObj}
                                </Dropdown.Item>
                              ))}
                            </DropdownButton>
                          )}
                          <Button variant="danger" onClick={() => handleDeleteItem(baseName)}>
                            {localization.delete}
                          </Button>
                        </InputGroup>
                      )}
                    </div>
                  );
                })}
              </div>
            </Collapse>
          </div>
        );
      })}
    </>
  );
};

export default CategoryItemsList;
