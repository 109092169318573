import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';

const StickyCategoryDropdown = ({ setShowOverlay, localization, language, categories, items }) => {
    const handleCategoryClick = (category) => {
        setShowOverlay(false);
        const categoryElement = document.getElementById(category);
        if (categoryElement) {
            categoryElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    return (
        <DropdownButton id="category-dropdown" title={localization.selectCategoryTitle} onClick={() => setShowOverlay(false)} className="sticky-category-btn">
            {items.map((category, index) => {
                const categoryName = categories[category.category]?.[language] || categories[category.category]?.en || category.category;
                return (
                    <Dropdown.Item key={index} onClick={() => handleCategoryClick(category.category)}>
                        {categoryName}
                    </Dropdown.Item>
                )
            })}
        </DropdownButton>
    );
};

export default StickyCategoryDropdown;
