import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import ReactGA from 'react-ga4';

const LanguageSelector = ({ language, setLanguage, localization }) => {

  const handleLanguageChange = (newLanguage) => {
    // Track button click event
    ReactGA.event({
      category: 'User Interaction',
      action: 'Language Dropdown Clicked',
      label: `Language Changed ${newLanguage}`,
      language: `${newLanguage}`
    });
    setLanguage(newLanguage);
    localStorage.setItem('language', newLanguage); // Save the selected language in localStorage
  };

  return (
    <DropdownButton
      id="language-dropdown"
      title={localization.language ? `${localization.language}: ${language === 'en' ? 'English' : 'Hindi'}` : 'Select Language'}
      onSelect={handleLanguageChange}
    >
      <Dropdown.Item eventKey="en">English</Dropdown.Item>
      <Dropdown.Item eventKey="hi">Hindi</Dropdown.Item>
    </DropdownButton>
  );
};

export default LanguageSelector;
